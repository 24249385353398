<template>
  <v-overlay
    color="secondary"
    class="primary--text"
    opacity="0.70"
    absolute
    :value="$store.state.cargando"
  >
    <v-layout column wrap text-center justify-center align-center>
      <v-flex>
        <v-img :src="$store.state.logo" width="300px"></v-img>
      </v-flex>
      <h2 class="my-5">Cargando...</h2>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-layout>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
