<template>
  <v-navigation-drawer
    app
    v-model="$store.state.navigationDrawer"
    class="primary white--text"
    clipped
    temporary
    width="260"
  >
    <v-list dark class="mx-3">
      <!-- v-list-item link @click="$router.push('/Hazlo-tu-mismo')"
				:class="$route.path=='/Hazlo-tu-mismo'?'primary darken-2':''">
				<v-list-item-title>Hazlo tu mismo</v-list-item-title>
			</v-list-item -->
      <v-list-group active-class="secondary--text">
        <template v-slot:activator>
          <v-list-item-title>Tiendas</v-list-item-title>
        </template>
        <v-list-item
          class="ml-6"
          link
          @click="$router.push('/Tiendas/Detal')"
          :class="$route.path == '/Tiendas/Detal' ? 'primary darken-2' : ''"
        >
          <v-list-item-title> Tiendas al Detal </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="ml-6"
          link
          @click="$router.push('/Tiendas/Mayor')"
          :class="$route.path == '/Tiendas/Mayor' ? 'primary darken-2' : ''"
        >
          <v-list-item-title> Ventas al Mayor </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item
        link
        href="https://ofimaniaenlinea.ofimania.com"
      >
        <v-list-item-title>Tienda Online</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="$router.push('/Productos')"
        :class="$route.path == '/Productos' ? 'primary darken-2' : ''"
      >
        <v-list-item-title>Productos</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="$router.push('/Contacto')"
        :class="$route.path == '/Contacto' ? 'primary darken-2' : ''"
      >
        <v-list-item-title>Contacto</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-flex fill-height>
      <Banners :imagenes="img" nav class="my-auto" />
    </v-flex>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import Banners from "../components/Banners.vue";

export default {
  created() {
    this.$store.state.cargando = true;
    this.axios
      .get("https://www.ofimania.com/API/Imagen/Banner-Movil")
      .then((response) => {
        this.img = response.data;
        this.$store.state.cargando = false;
      })
      .catch((error) => {
        this.$store.state.cargando = false;
        this.$store.state.alerta = {
          estado: true,
          tipo: "error",
          titulo: "Error de Conexion",
          info: "Verifique su Conexion a Internet",
        };
      });
  },

  data() {
    return {
      img: [],
    };
  },
  computed: {
    ...mapState(["movil"]),
  },
  components: {
    Banners,
  },
};
</script>
