<template>
  <v-footer dark height="auto" class="pa-0">
    <v-layout column>
      <v-layout
        wrap
        :justify-space-around="movil"
        :justify-space-between="!movil"
        align-center
        class="primary"
        v-if="!Admin"
      >
        <v-flex class="py-2 px-4" shrink>
          <v-btn text color="white" to="/Preguntas">
            Preguntas Frecuentes
          </v-btn>
        </v-flex>
        <v-layout column wrap shrink class="text-center py-2 px-4 mr-12">
          <v-flex>
            <strong class="subheading"> Conéctate con nosotros</strong>
          </v-flex>
          <v-layout>
            <v-flex>
              <v-btn
                dark
                icon
                href="https://api.whatsapp.com/send?phone=584149299013&text=¡Quiero%20conocer%20m%C3%A1s%20sobre%20sus%20productos%20y%20servicios!"
                target="blank"
              >
                <v-icon size="38px">mdi-whatsapp</v-icon>
              </v-btn>
            </v-flex>
            <v-flex>
              <v-btn dark icon to="/Contacto" target="blank">
                <v-icon size="30px">mdi-email-edit-outline</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-layout>
      <v-flex class="secondary lighten-1 black--text pa-2 text-center">
        <span
          >&copy;2019 — Desarrollado por
          <a
            class="wcFont"
            target="blank"
            href="https://wolfscodes.herokuapp.com/"
          >
            Wolf's Codes</a
          ></span
        >
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["movil"]),
  },
  props: {
    Admin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.wcFont {
  color: rgb(91, 177, 199);
  text-decoration: none;
  font-family: Satisfy;
}

.wcFont:hover {
  color: rgb(42, 139, 163);
}
</style>
