<template>
  <v-app-bar color="primary" dark app>
    <router-link to="/" active-class="secondary-text">
      <img
        v-if="!buscando || !movil"
        :src="$store.state.logo"
        height="50px"
        alt="Logo"
        class="mr-3 mt-2"
      />
      <img
        v-else
        src="../assets/minilogo.png"
        height="45px"
        alt="Logo"
        class="mr-3 mt-2"
      />
    </router-link>
    <v-text-field
      v-if="buscando"
      hide-details
      @keyup.enter.native="Busqueda()"
      color="warning"
      v-model="$store.state.search"
      label="Busca tu producto"
    />
    <v-spacer v-else></v-spacer>
    <v-btn @click="buscando = !buscando" class="d-none" icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-app-bar-nav-icon
      @click="$store.state.navigationDrawer = !$store.state.navigationDrawer"
      v-if="movil"
    />
    <v-toolbar-items v-else>
      <!-- v-btn active-class="secondary--text" color="white" text to="/Hazlo-tu-mismo">
				Hazlo tú mismo
			</v-btn-->
      <v-menu open-on-hover transition="slide-y-transition" offset-y auto>
        <template v-slot:activator="{ on }">
          <v-btn
            :color="
              $route.path == '/Tiendas/Detal' || $route.path == '/Tiendas/Mayor'
                ? 'secondary'
                : 'white'
            "
            text
            v-on="on"
          >
            Tiendas <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item @click="$router.push('/Tiendas/Detal')">
            <v-list-item-title
              :class="$route.path == '/Tiendas/Detal' ? 'primary--text' : ''"
              >Tiendas al detal</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="$router.push('/Tiendas/Mayor')">
            <v-list-item-title
              :class="$route.path == '/Tiendas/Mayor' ? 'primary--text' : ''"
              >Ventas al mayor</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn active-class="secondary--text" color="white" text href="https://ofimaniaenlinea.ofimania.com">
        Tienda Online
      </v-btn>
      <v-btn active-class="secondary--text" color="white" text to="/Productos">
        Productos
      </v-btn>
      <v-btn active-class="secondary--text" color="white" text to="/Contacto">
        Contacto
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      buscando: false,
      search: "",
    };
  },
  methods: {
    Busqueda() {
      this.$store.commit("actualizarArticulos");
      this.$router.push("/search=" + this.$store.state.search);
    },
  },
  computed: {
    ...mapState(["movil"]),
  },
};
</script>
