<template>
  <v-layout wrap>
    <v-carousel
      cycle
      :height="nav ? '210px' : 'auto'"
      hide-delimiters
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(imagen, i) in imagenes" :key="i">
        <v-layout align-start fill-height justify-center>
          <v-img
            width="cover"
            :height="nav ? '210px' : 'auto'"
            :src="imagen.imagen"
          >
            <template v-slot:placeholder>
              <v-layout fill-height align-center justify-center ma-0>
                <v-progress-circular
                  indeterminate
                  color="primary lighten-2"
                ></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-layout>
      </v-carousel-item>
    </v-carousel>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    imagenes: {
      type: Array,
      default: [
        "https://www.ofimania.com/img/logo.webp",
        "https://www.ofimania.com/img/logo.webp",
        "https://www.ofimania.com/img/logo.webp",
        "https://www.ofimania.com/img/logo.webp",
        "https://www.ofimania.com/img/logo.webp",
      ],
    },
    nav: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["movil"]),
  },
};
</script>

<style></style>
