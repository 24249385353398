import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";

Vue.use(Meta);
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Hazlo-tu-mismo",
      path: "/Hazlo-tu-mismo",
      component: () => import("./views/HazloTuMismo.vue"),
    },
    {
      name: "Descarga",
      path: "/Descarga/:hash",
      props: true,
      component: (hash) => import("./views/Descargas.vue"),
    },
    {
      name: "Preguntas",
      path: "/Preguntas",
      component: () => import("./views/Preguntas.vue"),
    },
    {
      name: "Productos",
      path: "/Productos",
      component: () => import("./views/Productos.vue"),
    },
    {
      name: "Contacto",
      path: "/Contacto",
      component: () => import("./views/Contacto.vue"),
    },
    {
      name: "OfertaDeEmpleo",
      path: "/Oferta-De-Empleo",
      component: () => import("./views/OfertaDeEmpleo.vue"),
    },
    {
      name: "Correo",
      path: "/Contacto/:correo",
      props: true,
      component: (correo) => import("./views/Contacto.vue"),
    },
    {
      name: "Detal",
      path: "/Tiendas/Detal",
      component: () => import("./views/Detal.vue"),
    },
    {
      name: "Mayor",
      path: "/Tiendas/Mayor",
      component: () => import("./views/Mayor.vue"),
    },
    {
      name: "Blog",
      path: "/Blog",
      component: () => import("./views/Blog.vue"),
    },
    {
      name: "Home",
      path: "/",
      component: () => import("./views/Inicio.vue"),
    },
  ],
});
