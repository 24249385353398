<template>
  <v-app id="app" v-resize="onResize">
    <NavBar />
    <NavDrawer />
    <v-main>
      <Alerta />
      <Cargando />
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Alerta from "./components/Alerta";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Cargando from "./components/Cargando.vue";
import NavDrawer from "./components/NavDrawer";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["onResize"]),
  },
  components: {
    NavDrawer,
    Cargando,
    Alerta,
    NavBar,
    Footer,
  },
};
</script>

<style>
* {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
